import React from 'react';
import { useNavigate } from 'react-router-dom';

const FinalPage: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/'); // Ana sayfaya yönlendirmek için
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md text-center">
      <img src="/images/success.ico" alt="Success" className="mx-auto mb-4 w-72 h-72" />
        <h1 className="text-2xl font-bold mb-4">Thank You!</h1>
        <p className="text-gray-700 mb-4">
        We've got your request! Our support team will get back to you shortly. 😊
        </p>
        <button
          onClick={handleGoBack}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Return to Home Page
        </button>
      </div>
    </div>
  );
};

export default FinalPage;