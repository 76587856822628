import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { useMoveAddress } from "../contexts/MoveAdressContext";
import { useBuildingContext,BuildingDetails } from "../contexts/BuildingDetailContext";


const BuildingDetail = () => {

    const navigate = useNavigate();
    const { moveOutAddress, moveInAddress } = useMoveAddress();
    const { moveOutBuilding, moveInBuilding, setMoveOutBuilding, setMoveInBuilding } = useBuildingContext();

    const [errors, setErrors] = useState<{ moveOutBuilding?: string; moveInBuilding?: string }>({});

    const handleBuildingDetailsChange = (
        key: string,
        value: string,
        setBuildingDetails: React.Dispatch<React.SetStateAction<any>>
    ) => {
        setBuildingDetails((prevDetails: any) => ({ ...prevDetails, [key]: value }));
    };


    const handleNextClick = () => {
        const newErrors: { moveOutBuilding?: string; moveInBuilding?: string } = {};

        if (!moveOutBuilding.type || !moveOutBuilding.floor || !moveOutBuilding.elevator) {
            newErrors.moveOutBuilding = "* All fields for move out building details are required.";
        }

        if (!moveInBuilding.type || !moveInBuilding.floor || !moveInBuilding.elevator) {
            newErrors.moveInBuilding = "* All fields for move in building details are required.";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        navigate("/moving-items-detail");
    };


    return (
        <div className="bg-gray-100">
            <Header />

            <section
                className="bg-cover bg-center text-white py-10"
                style={{
                    backgroundImage: "url('/images/building-moving-banner.jpg')",
                    backgroundSize: '100%', // Ensure the image covers the entire section
                    backgroundPosition: 'center 57%', // Center the image
                    backgroundRepeat: 'no-repeat',
                    minHeight: '43vh' // Prevent the image from repeating
                }}
            >

            </section>

            <div className="grid grid-cols-3 gap-4 p-4">
                {/* İlk kolon, 2 kolon genişliğinde */}
                <div className="col-span-2 text-white p-4">
                    <div className="bg-white text-purple-700 font-bold text-center py-4 mb-4 rounded-lg shadow-md">
                        <span className="flex items-center justify-center">
                            <img src="/images/integrity.png" alt="icon" className="w-10 h-10 mr-2" />
                            FIXED PRICE AND 100% INSURANCE - Our partner companies are trusted and hand-picked!
                        </span>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        {/* Card 1 - Move Out Location */}
                        <div className="bg-white p-6 rounded-lg shadow-md h-auto">
                            <div className="font-semibold text-gray-700 mb-4">MOVE OUT LOCATION</div>
                            {errors.moveInBuilding && <p className="text-red-500 text-sm">{errors.moveInBuilding}</p>}
                            <select className="border rounded p-2 mb-2 w-full text-black"
                                value={moveOutBuilding.type}
                                onChange={(e) => handleBuildingDetailsChange("type", e.target.value, setMoveOutBuilding)}
                            >
                                <option>I am living in a</option>
                                <option>Flat</option>
                                <option>House</option>
                                <option>Maisonette</option>
                                <option>Storage room</option>
                            </select>

                            <select className="border rounded p-2 mb-2 w-full text-black"
                                value={moveOutBuilding.floor}
                                onChange={(e) => handleBuildingDetailsChange("floor", e.target.value, setMoveOutBuilding)}
                            >
                                <option>Floor</option>
                                <option value="0">Ground floor</option>
                                <option value="1">1st floor</option>
                                <option value="2">2nd floor</option>
                                <option value="3">3rd floor</option>
                                <option value="4">4th floor</option>
                                <option value="5">5th floor</option>
                                <option value="6+">6th+ floor</option>
                                {/* Add more floor options as needed */}
                            </select>

                            <select className="border rounded p-2 mb-2 w-full text-black"
                                value={moveOutBuilding.elevator ? "Yes" : "No"}
                                onChange={(e) => handleBuildingDetailsChange("elevator", e.target.value, setMoveOutBuilding)}
                            >
                                <option>Elevator</option>
                                <option>No</option>
                                <option>1 - 6 persons</option>
                                <option>6+ persons</option>
                            </select>

                            <div className="mb-4">
                                <label className="text-gray-600 mb-1 block">How many bags and boxes should be moved?</label>
                                <div className="flex">
                                    {/* Range Input */}
                                    <input
                                        type="range"
                                        min="0"
                                        max="150"
                                        value={moveOutBuilding.bagCount || 0} // Ensure bagCount is always a number
                                        onChange={(e) =>
                                            setMoveOutBuilding((prev: BuildingDetails) => ({
                                                ...prev,
                                                bagCount: Number(e.target.value), // Convert value to number
                                            }))
                                        }
                                        className="w-full mr-4"
                                    />
                                    {/* Number Input */}
                                    <input
                                        type="number"
                                        value={moveOutBuilding.bagCount || 0} // Ensure bagCount is always a number
                                        onChange={(e) =>
                                            setMoveOutBuilding((prev: BuildingDetails) => ({
                                                ...prev,
                                                bagCount: Number(e.target.value), // Convert value to number
                                            }))
                                        }
                                        className="border rounded p-1 mt-2 w-16 text-black"
                                        placeholder="Amount"
                                    />
                                </div>
                            </div>

                            <textarea
                                placeholder="Notes on loading (e.g., further loading address, hillside location, etc.)"
                                className="border rounded p-2 w-full text-black mb-2 h-20"
                                value={moveOutBuilding.notes}
                                onChange={(e) => handleBuildingDetailsChange("notes", e.target.value, setMoveOutBuilding)}
                            />
                        </div>

                        {/* Card 2 - Move In Location */}
                        <div className="bg-white p-6 rounded-lg shadow-md h-auto">
                            <div className="font-semibold text-gray-700 mb-4">MOVE IN LOCATION</div>
                            {errors.moveInBuilding && <p className="text-red-500 text-sm">{errors.moveInBuilding}</p>}
                            <select className="border rounded p-2 mb-2 w-full text-black"
                                value={moveInBuilding.type}
                                onChange={(e) => handleBuildingDetailsChange("type", e.target.value, setMoveInBuilding)}
                            >
                                <option>I am living in a</option>
                                <option>Flat</option>
                                <option>House</option>
                                <option>Maisonette</option>
                                <option>Storage room</option>
                            </select>

                            <select className="border rounded p-2 mb-2 w-full text-black"
                                value={moveInBuilding.floor}
                                onChange={(e) => handleBuildingDetailsChange("floor", e.target.value, setMoveInBuilding)}
                            >
                                <option>Floor</option>
                                <option value="0">Ground floor</option>
                                <option value="1">1st floor</option>
                                <option value="2">2nd floor</option>
                                <option value="3">3rd floor</option>
                                <option value="4">4th floor</option>
                                <option value="5">5th floor</option>
                                <option value="6+">6th+ floor</option>
                                {/* Add more floor options as needed */}
                            </select>

                            <select className="border rounded p-2 mb-2 w-full text-black"
                                value={moveInBuilding.elevator ? "Yes" : "No"}
                                onChange={(e) => handleBuildingDetailsChange("elevator", e.target.value, setMoveInBuilding)}
                            >
                                <option>Elevator</option>
                                <option>No</option>
                                <option>1 - 6 persons</option>
                                <option>6+ persons</option>
                            </select>

                            <textarea
                                placeholder="Notes on loading (e.g., further loading address, hillside location, etc.)"
                                className="border rounded p-2 w-full text-black mb-2 h-20"
                                value={moveInBuilding.notes}
                                onChange={(e) => handleBuildingDetailsChange("notes", e.target.value, setMoveInBuilding)}
                            />
                        </div>
                    </div>

                    <div className="flex justify-end mt-4">
                        <button className="bg-green-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-green-700 focus:outline-none"
                            onClick={handleNextClick}>
                            Next
                        </button>
                    </div>

                </div>

                {/* Sidebar */}
                <div className="col-span-1 bg-white p-6 rounded-lg shadow-md h-auto">
                    <h3 className="font-semibold text-indigo-600 mb-4">Adress Detail</h3>
                    <ul className="space-y-2">
                        <li className="flex items-center text-gray-700">
                            <span className="checkmark mr-2 mr-2">Address of move out location:</span>
                            <span>{moveOutAddress.street}, {moveOutAddress.postcode}, {moveOutAddress.location}</span>
                        </li>
                        <li className="flex items-center text-gray-700">
                            <span className="checkmark mr-2 mr-2">Address of move in location:</span>
                            <br />
                            <span>{moveInAddress.street}, {moveInAddress.postcode}, {moveInAddress.location}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default BuildingDetail;


