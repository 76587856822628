import React from 'react';
import { useEffect, useState } from 'react';
import Header from '../components/Header';
import { useMoveItemDetail } from '../contexts/MoveItemDetailContext';
import { useMoveAddress } from '../contexts/MoveAdressContext';
import { useBuildingContext } from '../contexts/BuildingDetailContext';
import ClientModal from '../components/ClientModal';
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import { useNavigate } from "react-router-dom";


const MoveSummary: React.FC = () => {

  const navigate = useNavigate();  
  const { selectedItems } = useMoveItemDetail();
  const { moveOutAddress, moveInAddress } = useMoveAddress();
  const { moveOutBuilding, moveInBuilding } = useBuildingContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contact, setContact] = useState({
    title: '',
    fullname: '',
    email: '',
    telephone: '',
    message: '',
    moveDate: new Date().toISOString()
  });

  const handleNextClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { id, value } = e.target;
    setContact((prevContact) => ({ ...prevContact, [id]: value }));
  };

  const handleDateChange = (newValue: DateValueType) => {
    const date = newValue && newValue.startDate ? new Date(newValue.startDate) : new Date();
    setContact((prevContact) => ({ ...prevContact, moveDate: date.toISOString() }));
  };


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // LocalStorage'daki bilgileri al
    const moveOutBuilding = JSON.parse(localStorage.getItem('moveOutBuilding') || '{}');
    const moveInBuilding = JSON.parse(localStorage.getItem('moveInBuilding') || '{}');
    const moveOutAddress = JSON.parse(localStorage.getItem('moveOutAddress') || '{}');
    const moveInAddress = JSON.parse(localStorage.getItem('moveInAddress') || '{}');
    const selectedItems = JSON.parse(localStorage.getItem('selectedItems') || '[]');


    moveOutBuilding.elevator = Boolean(moveOutBuilding.elevator);
    moveInBuilding.elevator = Boolean(moveInBuilding.elevator);

    console.log('selectedItems:', selectedItems);

    // Form bilgileri ve localStorage bilgilerini birleştir
    const dataToSend = {
      contact,
      moveOutBuilding,
      moveInBuilding,
      moveOutAddress,
      moveInAddress,
      selectedItems
    };

    console.log('dataToSend:', JSON.stringify(dataToSend, null, 2));

    // API'ye gönder
    try {
      const response = await fetch('https://happymoveapi.runasp.net/api/Move', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSend)
      });

      if (response.ok) {
        console.log('Form successfully submitted');
        localStorage.clear();
        // Başarılı gönderim sonrası işlemler
        navigate("/move-request-success");
      } else {
        console.error('Form submission failed');
        // Hata durumunda işlemler
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // Hata durumunda işlemler
    }

    // Modal'ı kapat
    setIsModalOpen(false);
  };

  return (

    <div className="bg-gray-100 min-h-screen">
      <Header />

      {/* Header Banner */}
      <div className="bg-white text-purple-700 font-bold text-center py-4 mb-4 mx-5 rounded-lg shadow-md mt-5">
        <span className="flex items-center justify-center">
          <img src="/images/integrity.png" alt="icon" className="w-10 h-10 mr-2" />
          FIXED PRICE AND 100% INSURANCE - Our partner companies are trusted and hand-picked!
        </span>
      </div>

      {/* Main Container */}
      <div className="grid grid-cols-12 gap-4 px-5">
        {/* Sol Kolon */}
        <div className="col-span-3 space-y-3 mb-4 ml-8 mr-8">
          <div className="mx-auto bg-white border border-gray-200 rounded-lg shadow-md">
            <div className="bg-blue-500 text-white text-lg font-semibold p-3 rounded-t-lg">
              Info
            </div>
            <div className="p-4">
              <p>
                <span className="text-grey">Adress of move out location:</span><br />
                <span className="summary-text">{moveInAddress?.street}</span>,
                <span className="summary-text">{moveInAddress?.postcode}</span>,
                <span className="summary-text">{moveInAddress?.location}</span>
              </p>
              <p>
                <span className="text-grey">Adree of move in location:</span><br />
                <span className="summary-text">{moveOutAddress?.street}</span>,
                <span className="summary-text">{moveOutAddress?.postcode}</span>
                <span className="summary-text">{moveOutAddress?.location}</span>
              </p>
            </div>
          </div>
          <div className="mx-auto bg-white border border-gray-200 rounded-lg shadow-md">
            <div className="bg-gray-700 text-white text-lg font-semibold p-3 rounded-t-lg">
              Call Us Free
            </div>
            <div className="p-4">

              <p>
                <span className="text-grey center">Need some help?</span><br />
              </p>
              <div className="flex items-center">
                <img src="/images/call-center.png" alt="Call Center" className="w-16 h-16 mr-4" />
                <div>
                  <span className="summary-text block">You can reach us at any time. Free of charge and non-binding.</span>
                  <span className="summary-text block">Phone: +1-800-123-4567</span>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* Orta Kolon */}
        <div className="col-span-6 grid grid-cols-3 gap-4">
          <div className="bg-white border border-gray-200 rounded-lg shadow-md mb-4 flex flex-col min-h-72 max-h-80">
            <div className="bg-gray-100 text-black text-lg font-semibold p-4 rounded-t-lg">
              Move Out Location
            </div>
            <div className="p-4 flex-grow">

              <p>
                <span className="text-grey">Building Type:</span><br />
                <span className="summary-text">{moveOutBuilding?.type}</span>
              </p>
              <p>
                <span className="text-grey">Floor:</span><br />
                <span className="summary-text">{moveOutBuilding?.floor}</span>
              </p>
              <p>
                <span className="text-grey">Elevator:</span><br />
                <span className="summary-text">{moveOutBuilding?.elevator}</span>
              </p>
              <p>
                <span className="text-grey">How many bags and boxes should be moved?
                </span><br />
                <span className="summary-text">{moveOutBuilding?.bagCount}</span>
              </p>
            </div>
          </div>

          <div className="bg-white border border-gray-200 rounded-lg shadow-md mb-4 flex flex-col">
            <div className="bg-gray-100 text-black text-lg font-semibold p-4 rounded-t-lg">
              List of Goods To Be Moved
            </div>
            <div className="p-4 flex-grow">
              <ul className="space-y-2 text-gray-700">
                {selectedItems.map((item) => (
                  <div key={item.id} className="flex justify-between">
                    <span>{item.name}</span>
                    <span className="ml-auto">X {item.quantity}</span>
                  </div>
                ))}
              </ul>
            </div>
          </div>

          <div className="bg-white border border-gray-200 rounded-lg shadow-md mb-4 flex flex-col h-64">
            <div className="bg-gray-100 text-black text-lg font-semibold p-4 rounded-t-lg">
              Move In Location
            </div>
            <div className="p-4 flex-grow">

              <p>
                <span className="text-grey">Building Type:</span><br />
                <span className="summary-text">{moveInBuilding?.type}</span>
              </p>
              <p>
                <span className="text-grey">Floor:</span><br />
                <span className="summary-text">{moveInBuilding?.floor}</span>
              </p>
              <p>
                <span className="text-grey">Elevator:</span><br />
                <span className="summary-text">{moveInBuilding?.elevator}</span>
              </p>

            </div>
          </div>

        </div>

        {/* Sağ Kolon */}
        <div className="col-span-3 space-y-3 mb-4 mr-8">
          <div className="max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow-md">
            <div className="bg-gray-700 text-white text-lg font-semibold p-3 rounded-t-lg flex justify-between">
              Included In Price
            </div>
            <div>
              <div className="p-4">
                <p><strong>Included:</strong></p>
                <ul className="list-disc list-inside">
                  <li>Vehicle and petrol</li>
                  <li>Competent moving team</li>
                  <li>Protective material + fastening straps</li>
                  <li>Transport + liability insurance</li>
                  <li>Moving of listed furniture</li>
                  <li>Personal MoveAgain Consulting</li>
                </ul>
                <p><strong>Not included:</strong></p>
                <ul className="list-disc list-inside">
                  <li>Electrical work (Lamps, Internet)</li>
                  <li>Parking Reservation</li>
                  <li>Kitchen assembly and disassembly</li>
                </ul>
                <p>For more information, visit our FAQ.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-12 flex justify-center items-center mt-4 mb-4">
          <button onClick={handleNextClick} className="bg-blue-500 text-white px-4 py-2 rounded ">Next</button>
        </div>

      </div>

      <ClientModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <form onSubmit={handleSubmit}>
        
        <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
              Title
            </label>
            <select
              className="border rounded p-2 mb-2 w-full text-black"
              id="title"
              value={contact.title}
              onChange={handleInputChange}
              required
            >
              <option value="">Select your title</option>
              <option value="Mr.">Mr.</option>
              <option value="Mrs.">Mrs.</option>
              <option value="Ms.">Ms.</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              className="border rounded p-2 mb-2 w-full text-black"
              id="fullname"
              type="text"
              placeholder="Your name"
              value={contact.fullname}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="border rounded p-2 mb-2 w-full text-black"
              id="email"
              type="email"
              placeholder="Your email"
              value={contact.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Telephone
            </label>
            <input
              className="border rounded p-2 mb-2 w-full text-black"
              id="telephone"
              type="telephone"
              placeholder="Your telephone"
              value={contact.telephone}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="moveDate">
              Move Date
            </label>
            <Datepicker
              containerClassName="border rounded p-2 mb-2 w-full text-black"
              inputId="moveDate"
              inputName="moveDate"
              asSingle={true}
              useRange={false}
              placeholder="Enter Move Date"
              value={{ startDate: new Date(contact.moveDate), endDate: new Date(contact.moveDate) }}
              onChange={handleDateChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
              Message
            </label>
            <textarea
              className="border rounded p-2 mb-2 w-full text-black"
              id="message"
              placeholder="Your message"
              value={contact.message}
              onChange={handleInputChange}
              required
            ></textarea>
          </div>
          <div className="flex justify-center">
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Book Now
            </button>
          </div>
        </form>
      </ClientModal>
    </div>
  );
};

export default MoveSummary;
