import React, { useState, useEffect } from 'react';
import HeaderMain from '../components/HeaderMain';
import { useNavigate } from 'react-router-dom';
import Modal from '../components/Modal';

const testimonials = [
  {
    name: 'Musharof Chy',
    position: 'Moved to Berlin',
    review: "The team made my move to Berlin seamless. From handling all the paperwork to providing support every step of the way, they made the transition stress-free!",
  },
  {
    name: 'Devid Weilium',
    position: 'Relocated to New York',
    review: "Fantastic service! Moving to New York was a big step, but they took care of everything with professionalism and attention to detail. Highly recommended!",
  },
  {
    name: 'Lethium Frenci',
    position: 'Relocated to Dubai',
    review: "I couldn’t have asked for a better experience. Moving to Dubai was hassle-free thanks to their expert team, who took care of everything from logistics to legalities.",
  },
];

const blogs = [
  {
    title: 'Moving to Berlin: Tips for a Smooth Transition',
    description: 'Discover essential tips for moving to Berlin, including finding housing, navigating visa requirements, and adapting to the city’s unique culture.',
    author: 'Samyul Joshi',
    role: 'Relocation Specialist',
    date: '2025',
    category: 'Berlin',
    imageUrl: "/images/Berlin.jpg",
  },
  {
    title: 'Moving to New York: What You Need to Know',
    description: 'Learn about the key steps to successfully relocate to New York City, from navigating the rental market to understanding the city’s lifestyle and job market.',
    author: 'Musharof Chy',
    role: 'Relocation Expert',
    date: '2025',
    category: 'New York',
    imageUrl: "/images/New-York.jpg",
  },
  {
    title: 'Moving to Dubai: Your Guide to Relocation',
    description: 'Get comprehensive insights on moving to Dubai, including information on visas, housing, work opportunities, and the unique culture in this bustling city.',
    author: 'Lethium Deo',
    role: 'International Move Consultant',
    date: '2025',
    category: 'Dubai',
    imageUrl: "/images/dubai.jpg",
  },
];


const HomePage: React.FC = () => {

  const [bgImageLoaded, setBgImageLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const img = new Image();
    img.src = '/images/move_main_image.jpg';
    img.onload = () => setBgImageLoaded(true);
  }, []);


  const openModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleServiceSelection = (serviceType: string) => {
    navigate('/move-address-detail', { state: { serviceType } });
  };

  return (
    <div>
      <HeaderMain />
      {/* Hero Bölümü */}
      <section
        className="relative bg-cover bg-center text-white py-20 hero-section"
        style={{
          backgroundImage: bgImageLoaded ? "url('/images/move_main_image.jpg')" : 'none',
          backgroundSize: '100%', // Zoom out etkisi için background boyutunu %120 yaptık
          backgroundPosition: 'center', // Ortalıyor
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh', // Tam ekran yüksekliği
        }}
      >
      
        <div className="absolute inset-0 bg-black opacity-50"></div>

        <div className="container mx-auto px-4 flex flex-col lg:flex-row items-center relative">
          <div className="w-full lg:max-w-xl mt-60 relative z-10">
            <h1 className="text-4xl font-bold mb-4">Your trusted moving platform across World!</h1>
            <p className="mb-6 font-medium">
              TAKE A LOOK AT OUR SERVICES AND BOOK YOUR MOVE TODAY! GET YOUR PRICE DISPLAYED WITHIN 3 MINUTES!
            </p>
            <div className="bg-white p-6 rounded-lg shadow-md text-gray-800">
              <div className="text-center">
                <h2 className="text-lg font-semibold mb-4">Please select the type of service:</h2>
                <div className="flex justify-center space-x-4">
                <button
                    className="bg-gray-100 hover:bg-purple-500 hover:text-white p-4 rounded-lg text-center button-hover"
                    onClick={() => handleServiceSelection('Residential Move')}
                  >
                    <img src="/images/house.png" alt="ResidentialMoving" className="mx-auto mb-2 w-12 h-12 hover:text-white" />
                    <span>Residential Move</span>
                  </button>

                  <button
                    className="bg-gray-100 hover:bg-purple-500 hover:text-white p-4 rounded-lg text-center button-hover"
                    onClick={() => handleServiceSelection('Commercial Move')}
                  >
                    <img src="/images/building.png" alt="CommercialMoving" className="mx-auto mb-2 w-12 h-12" />
                    <span>Commercial Move</span>
                  </button>

                  <button
                    className="bg-gray-100 hover:bg-purple-500 hover:text-white p-4 rounded-lg text-center button-hover"
                    onClick={() => handleServiceSelection('Employee Relocation')}
                  >
                    <img src="/images/handshake.png" alt="EmployeeMoving" className="mx-auto mb-2 w-12 h-12" />
                    <span>Employee Relocation</span>
                  </button>

                </div>

                <p className="mt-4 text-center">
                  Already got an offer from us? <a href="#" onClick={openModal} className="text-blue-500">Click here</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <form>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              placeholder="Your name"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Your email"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
              Message
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="message"
              placeholder="Your message"
              required
            ></textarea>
          </div>
          <div className="flex justify-center">
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <section className="container mx-auto py-16 px-4 text-center">
        <h2 className="text-3xl font-semibold mb-8 purple-bold-title">Most reliable moving service at competitive rates.</h2>
        <p className="mb-8">Find out how we can make your move smooth and hassle-free!</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div>
            <img src="/images/person-stress.png" alt="Stress Free Relocation" className="mx-auto mb-2 w-12 h-12" />
            <h3 className="text-lg font-semibold purple-title">STRESS-FREE RELOCATION</h3>
            <p>We will work hand in hand with you to deliver a swift and well-planned move.</p>
          </div>
          <div>
            <img src="/images/hr-group.png" alt="Reliable Team" className="mx-auto mb-2 w-12 h-12" />
            <h3 className="text-lg font-semibold purple-title">A RELIABLE TEAM</h3>
            <p>Our moving team will handle your belongings with the utmost care and consideration. You can rest assured that every stage of your move is in safe hands.</p>
          </div>
          <div>
            <img src="/images/time-twenty-four.png" alt="Tailor-made Service" className="mx-auto mb-2 w-12 h-12" />
            <h3 className="text-lg font-semibold purple-title">TAILOR-MADE SERVICE</h3>
            <p>Our team works closely with you to capture every detail for your moving requirements.</p>
          </div>
          <div>
            <img src="/images/hand-holding-usd.png" alt="Transparent Prices" className="mx-auto mb-2 w-12 h-12" />
            <h3 className="text-lg font-semibold purple-title">TRANSPARENT PRICES</h3>
            <p>We will ensure to provide you with transparent prices, meaning no hidden costs and to achieve the best possible customer satisfaction.</p>
          </div>
        </div>
      </section>

      <section className="bg-white py-16">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row md:space-x-8">
            <div className="md:w-1/2 mb-9 md:mb-0">
              <img src="/images/move-world.jpg" alt="World Map" className="rounded-lg w-full" />
            </div>
            <div className="md:w-1/2 space-y-8">
              <div>
                <h3 className="text-xl font-semibold purple-bold-title">STEP 1: Select time and location</h3>
                <h4 className="text-lg font-medium purple-title">When and where are you moving to?</h4> {/* Added subtitle with bullet point */}
                <p>With HappyMove, you can easily move from A to B at any time of the year. Simply start by entering the postcode of your current and new home. Complete the form by adding your contact details.</p>
              </div>
              <div>
                <h3 className="text-xl font-semibold purple-bold-title">STEP 2: Select furniture inventory</h3>
                <h4 className="text-lg font-medium purple-title">What would you like to move?</h4> {/* Added subtitle with bullet point */}
                <p>Our services can be customized to suit your needs, with everything planned down to the last detail. Next, provide details on the items you would like to move. From the smallest to the biggest item, our moving calculator will ensure an accurate price quotation.</p>
              </div>
              <div>
                <h3 className="text-xl font-semibold purple-bold-title">STEP 3: Secure & easy booking</h3>
                <h4 className="text-lg font-medium purple-title">Ready to book?</h4> {/* Added subtitle with bullet point */}
                <p>After you receive a booking confirmation, you can lean back. HappyMove will ensure that every step of your relocation is delivered according to schedule and within your budget.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-50 py-16">
        <div className="max-w-6xl mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold mb-4 purple-bold-title">What Our Customer Say</h2>
          <p className="text-gray-600 mb-12">
            Stories from Our Customers: Dive into Genuine Experiences and See How Our Services Transformed Their Moving Journeys Across Cities Around the World!
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
                <div className="flex justify-center mb-4">
                  <div className="flex items-center">
                    <span className="text-yellow-500 text-xl">★★★★★</span>
                  </div>
                </div>
                <p className="text-gray-700 mb-6">"{testimonial.review}"</p>
                <div className="flex items-center justify-center">
                  <div>
                    <h4 className="text-lg font-semibold">{testimonial.name}</h4>
                    <p className="text-gray-500">{testimonial.position}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-gray-50 py-16">
        <div className="max-w-6xl mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold mb-4 purple-bold-title">Our Latest Blogs</h2>
          <p className="text-gray-600 mb-12">
            There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {blogs.map((blog, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
                <img src={blog.imageUrl} alt={blog.title} className="w-full h-48 object-cover" />
                <div className="p-6">
                  <div className="flex justify-between items-center mb-4">
                    <span className="bg-blue-100 text-blue-800 text-sm font-semibold px-3 py-1 rounded">{blog.category}</span>
                  </div>
                  <h3 className="text-lg font-bold mb-2">{blog.title}</h3>
                  <p className="text-gray-600 mb-4">{blog.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

    </div>
  );
};

export default HomePage;