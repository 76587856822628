import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useMoveAddress } from "../contexts/MoveAdressContext";
import { useBuildingContext } from "../contexts/BuildingDetailContext";
import { useMoveItemDetail, Item } from "../contexts/MoveItemDetailContext";

function MovingItemsDetail() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<{ id: number; categoryName: string }[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(categories[0]?.id || null);
  const { items, setItems, selectedItems, setSelectedItems, quantities, setQuantities } = useMoveItemDetail();
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingItems, setLoadingItems] = useState(false);
  const [isCardOpen, setIsCardOpen] = useState<{ [key: string]: boolean }>({
    listOfGoods: true,
    info: false,
    moveOutAddress: false,
    moveInAddress: false,
    includedInPrice: false,
  });

  useEffect(() => {
    // API'den kategorileri almak için fetch çağrısı
    const fetchCategories = async () => {
      try {
        const response = await fetch("https://happymoveapi.runasp.net/api/Category/GetList");
        const data = await response.json();
        if (Array.isArray(data)) {
          setCategories(data);
          setSelectedCategory(data[0]?.id || null);
        } else {
          console.error("Error: API did not return an array");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoadingCategories(false);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory !== null) {
      setLoadingItems(true);
      // Seçilen kategoriye ait itemları almak için fetch çağrısı
      const fetchItems = async () => {
        try {
          const response = await fetch(`https://happymoveapi.runasp.net/api/Category/GetItemList/${selectedCategory}`);
          if (!response.ok) {
            throw new Error(`Error fetching items: ${response.statusText}`);
          }
          const data = await response.json();
          setItems(data);
        } catch (error) {
          console.error("Error fetching items:", error);
        } finally {
          setLoadingItems(false);
        }
      };

      fetchItems();
    }
  }, [selectedCategory]);


  const toggleCard = (card: string) => {
    setIsCardOpen((prev) => {
      const newState = Object.keys(prev).reduce((acc, key) => {
        acc[key] = key === card ? !prev[card] : false;
        return acc;
      }, {} as { [key: string]: boolean });
      return newState;
    });
  };

  const { moveOutAddress, moveInAddress } = useMoveAddress();
  const { moveOutBuilding, moveInBuilding } = useBuildingContext();

  const handleCategoryClick = (categoryId: number) => {
    setSelectedCategory(categoryId);
  };

  const handleQuantityChange = (itemId: string, amount: number) => {
    setQuantities((prev) => {
      const updatedQuantity = Math.max(0, (prev[itemId] || 0) + amount);
      return { ...prev, [itemId]: updatedQuantity };
    });

    setSelectedItems((prev) => {
      const item = items.find((item) => item.id === itemId);
      if (item) {
        const existingItem = prev.find((i) => i.id === itemId);
        const updatedQuantity = Math.max(0, (quantities[itemId] || 0) + amount);
        if (existingItem) {
          if (updatedQuantity === 0) {
            return prev.filter((i) => i.id !== itemId);
          } else {
            return prev.map((i) => (i.id === itemId ? { ...i, quantity: updatedQuantity } : i));
          }
        } else {
          return [...prev, { ...item, quantity: updatedQuantity }];
        }
      }
      return prev;
    });
  };

  const handleNext = () => {
    setSelectedItems(selectedItems);
    navigate('/move-summary');
  };

  useEffect(() => {
    const total = Object.values(quantities).reduce((sum, qty) => sum + qty, 0);
  }, [quantities]);

  const selectedCategoryName = categories.find((cat) => cat.id === selectedCategory)?.categoryName || "Category";

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header />

      {/* Header Banner */}
      <div className="bg-white text-purple-700 font-bold text-center py-4 mb-4 mx-5 rounded-lg shadow-md mt-5">
        <span className="flex items-center justify-center">
          <img src="/images/integrity.png" alt="icon" className="w-10 h-10 mr-2" />
          FIXED PRICE AND 100% INSURANCE - Our partner companies are trusted and hand-picked!
        </span>
      </div>

      {/* Main Container */}
      <div className="grid grid-cols-12 gap-4 px-5">
        {/* Sol Kolon */}
        <div className="col-span-3 space-y-3 mb-4 ml-8 mr-8">
          <div className="mx-auto bg-white border border-gray-200 rounded-lg shadow-md">
            <div className="bg-gray-700 text-white text-lg font-semibold p-3 rounded-t-lg">
              Category
            </div>
            <div className="p-4">
            {loadingCategories ? (
              <p>Loading categories...</p>
            ) : (
              <ul className="space-y-2 text-gray-700">
                {categories.map((category, index) => (
                  <li
                    key={category.id}
                    className={`cursor-pointer ${selectedCategory === category.id ? "text-red-600 font-bold" : ""
                      }`}
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    {category.categoryName}
                    {index < categories.length - 1 && <hr className="border-dotted border-gray-300 my-3" />}
                  </li>
                ))}
              </ul>
            )}
          </div>
          </div>
        </div>

        {/* Orta Kolon */}
        <div className="col-span-6 bg-white border border-gray-200 rounded-lg shadow-md mb-4">
          <div className="bg-gray-100 text-black text-lg font-semibold p-4 rounded-t-lg">
            {selectedCategoryName}
          </div>
          <div className="p-4">
            {items
              .map((item) => (
                <div key={item.id} className="flex items-center justify-between py-2 border-b">
                  <div className="flex items-center gap-4">
                    {/* <img src={item.icon} alt={item.name} className="w-20 h-20" /> */}
                    <span className="text-gray-700">{item.name}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      className="text-gray-500 bg-gray-100 px-2 py-1 rounded"
                      onClick={() => handleQuantityChange(item.id, -1)}
                    >
                      -
                    </button>
                    <input
                      type="text"
                      value={quantities[item.id] || 0}
                      readOnly
                      className="w-14 h-14 text-center border rounded border-gray-300"
                    />
                    <button
                      className="text-gray-500 bg-gray-100 px-2 py-1 rounded"
                      onClick={() => handleQuantityChange(item.id, 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <div className="p-4">
            <label htmlFor="additionalInfo" className="block text-gray-700 font-semibold mb-2">
              Additional Information
            </label>
            <textarea
              id="additionalInfo"
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
              className="w-full h-32 p-2 border rounded border-gray-300"
              placeholder="Enter any additional information here..."
            ></textarea>
          </div>
          <div className="flex justify-end p-4">
            <button onClick={handleNext} className="bg-blue-500 text-white px-4 py-2 rounded">Next</button>
          </div>
        </div>

        {/* Sağ Kolon */}
        <div className="col-span-3 space-y-3 mb-4 mr-8">
          <div className="max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow-md">
            <div className="bg-orange-600 text-white text-lg font-semibold p-3 rounded-t-lg flex justify-between">
              List of goods to be moved
              <button className="ml-auto" onClick={() => toggleCard("listOfGoods")}>
                {isCardOpen.listOfGoods ? "▲" : "▼"}
              </button>
            </div>
            <div
              className={`overflow-hidden transition-all duration-500 ${isCardOpen.listOfGoods ? "max-h-screen" : "max-h-0"
                }`}
            >
              <div className="p-4">
                {selectedItems.map((item) => (
                  <div key={item.id} className="flex justify-between">
                    <span>{item.name}</span>
                    <span>x {quantities[item.id]}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow-md">
            <div className="bg-purple-900 text-white text-lg font-semibold p-3 rounded-t-lg flex justify-between">
              Info
              <button className="ml-auto" onClick={() => toggleCard("info")}>
                {isCardOpen.info ? "▲" : "▼"}
              </button>
            </div>
            <div
              className={`overflow-hidden transition-all duration-500 ${isCardOpen.info ? "max-h-screen" : "max-h-0"
                }`}
            >
              <div className="p-4">
                <p><span className="bold-grey-text">Adress of move out location: </span>, <br />
                  <span className="bold-darkgrey-text">{moveOutAddress?.street}</span>,
                  <span className="bold-darkgrey-text">{moveOutAddress?.postcode}</span>,
                  <span className="bold-darkgrey-text">{moveOutAddress?.location}</span></p>
                <p><span className="bold-grey-text">Adress of move in location: </span> <br />
                  <span className="bold-darkgrey-text">{moveInAddress?.street}</span>,
                  <span className="bold-darkgrey-text">{moveInAddress?.postcode}</span>,
                  <span className="bold-darkgrey-text">{moveInAddress?.location}</span></p>
              </div>
            </div>
          </div>
          <div className="max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow-md">
            <div className="bg-gray-700 text-white text-lg font-semibold p-3 rounded-t-lg flex justify-between">
              Move Out Location
              <button className="ml-auto" onClick={() => toggleCard("moveOutAddress")}>
                {isCardOpen.moveOutAddress ? "▲" : "▼"}
              </button>
            </div>
            <div
              className={`overflow-hidden transition-all duration-500 ${isCardOpen.moveOutAddress ? "max-h-screen" : "max-h-0"
                }`}
            >
              <div className="p-4">
                <p>
                  <span className="bold-grey-text">I'm living in a:</span><br />
                  <span className="bold-darkgrey-text">{moveOutBuilding?.type}</span>
                </p>
                <p>
                  <span className="bold-grey-text">Floor:</span><br />
                  <span className="bold-darkgrey-text">{moveOutBuilding?.floor}</span>
                </p>
                <p>
                  <span className="bold-grey-text">Elevator:</span><br />
                  <span className="bold-darkgrey-text">{moveOutBuilding?.elevator}</span>
                </p>
                <p>
                  <span className="bold-grey-text">How many bags and boxes should be moved?:</span><br />
                  <span className="bold-darkgrey-text">{moveOutBuilding?.bagCount}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow-md">
            <div className="bg-gray-700 text-white text-lg font-semibold p-3 rounded-t-lg flex justify-between">
              Move In Location
              <button className="ml-auto" onClick={() => toggleCard("moveInAddress")}>
                {isCardOpen.moveInAddress ? "▲" : "▼"}
              </button>
            </div>
            <div
              className={`overflow-hidden transition-all duration-500 ${isCardOpen.moveInAddress ? "max-h-screen" : "max-h-0"
                }`}
            >
              <div className="p-4">
                <p>
                  <span className="bold-grey-text">I'm living in a:</span><br />
                  <span className="bold-darkgrey-text">{moveInBuilding?.type}</span>
                </p>
                <p>
                  <span className="bold-grey-text">Floor:</span><br />
                  <span className="bold-darkgrey-text">{moveInBuilding?.floor}</span>
                </p>
                <p>
                  <span className="bold-grey-text">Elevator:</span><br />
                  <span className="bold-darkgrey-text">{moveInBuilding?.elevator}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow-md">
            <div className="bg-gray-700 text-white text-lg font-semibold p-3 rounded-t-lg flex justify-between">
              Included In Price
              <button className="ml-auto" onClick={() => toggleCard("includedInPrice")}>
                {isCardOpen.includedInPrice ? "▲" : "▼"}
              </button>
            </div>
            <div
              className={`overflow-hidden transition-all duration-500 ${isCardOpen.includedInPrice ? "max-h-screen" : "max-h-0"
                }`}
            >
              <div className="p-4">
                <p><strong>Included:</strong></p>
                <ul className="list-disc list-inside">
                  <li>Vehicle and petrol</li>
                  <li>Competent moving team</li>
                  <li>Protective material + fastening straps</li>
                  <li>Transport + liability insurance</li>
                  <li>Moving of listed furniture</li>
                  <li>Personal MoveAgain Consulting</li>
                </ul>
                <p><strong>Not included:</strong></p>
                <ul className="list-disc list-inside">
                  <li>Electrical work (Lamps, Internet)</li>
                  <li>Parking Reservation</li>
                  <li>Kitchen assembly and disassembly</li>
                </ul>
                <p>For more information, visit our FAQ.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MovingItemsDetail;