import React from 'react';
import { useEffect, useState } from 'react';


interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

const ClientModal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      if (isOpen) {
        setIsVisible(true);
      } else {
        setTimeout(() => setIsVisible(false), 300); // Match the duration of the animation
      }
    }, [isOpen]);
  

    if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className="bg-white rounded-lg shadow-lg p-8 z-10 w-11/12 md:w-1/2 lg:w-1/3 transform transition-transform duration-300 ease-in-out translate-y-0 relative">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-xl font-semibold mb-4">Contact Information</h2>
        {children}
      </div>
    </div>
  );

};

export default ClientModal;