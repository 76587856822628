import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";

export interface BuildingDetails {
  type: string;
  floor: number;
  elevator: boolean;
  bagCount?: number;
  notes: string;
}

interface BuildingContextProps {
  moveOutBuilding: BuildingDetails;
  moveInBuilding: BuildingDetails;
  setMoveOutBuilding: React.Dispatch<React.SetStateAction<BuildingDetails>>;
  setMoveInBuilding: (details: BuildingDetails) => void;
}

const BuildingContext = createContext<BuildingContextProps | undefined>(undefined);

export const BuildingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [moveOutBuilding, setMoveOutBuilding] = useState<BuildingDetails>(() => {
    const savedMoveOutBuilding = localStorage.getItem("moveOutBuilding");
    return savedMoveOutBuilding ? JSON.parse(savedMoveOutBuilding) : {
      type: "",
      floor: "",
      elevator: false,
      bagCount: 25,
      notes: "",
    };
  });

  const [moveInBuilding, setMoveInBuilding] = useState<BuildingDetails>(() => {
    const savedMoveInBuilding = localStorage.getItem("moveInBuilding");
    return savedMoveInBuilding ? JSON.parse(savedMoveInBuilding) : {
      type: "",
      floor: "",
      elevator: false,
      notes: "",
    };
  });

  useEffect(() => {
    localStorage.setItem("moveOutBuilding", JSON.stringify(moveOutBuilding));
  }, [moveOutBuilding]);

  useEffect(() => {
    localStorage.setItem("moveInBuilding", JSON.stringify(moveInBuilding));
  }, [moveInBuilding]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      localStorage.removeItem("moveOutBuilding");
      localStorage.removeItem("moveInBuilding");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);


  return (
    <BuildingContext.Provider value={{ moveOutBuilding, moveInBuilding, setMoveOutBuilding, setMoveInBuilding }}>
      {children}
    </BuildingContext.Provider>
  );
};

export const useBuildingContext = () => {
  const context = useContext(BuildingContext);
  if (!context) {
    throw new Error("useBuildingContext must be used within a BuildingProvider");
  }
  return context;
};

