import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home';
import MoveAddressDetail from './pages/MoveAdressDetail';
import BuildingDetail from './pages/BuildingDetail';
import MovingItemsDetail from './pages/MovingItemsDetail';
import MoveSummary from './pages/MoveSummary';
import Footer from './components/Footer';
import { MoveAddressProvider } from './contexts/MoveAdressContext';
import { BuildingProvider } from './contexts/BuildingDetailContext';
import './App.css';
import { MoveItemDetailProvider } from './contexts/MoveItemDetailContext';
import FinalPage from './pages/FinalPage';

const App: React.FC = () => {
  return (
    <Router>
      <MoveAddressProvider>
        <BuildingProvider>
          <MoveItemDetailProvider>            
          <div className="flex flex-col min-h-screen">
            <div className="flex-grow">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/move-address-detail" element={<MoveAddressDetail />} />
                <Route path="/building-detail" element={<BuildingDetail />} />
                <Route path="/moving-items-detail" element={<MovingItemsDetail />} />
                <Route path="/move-summary" element={<MoveSummary />} />
                <Route path="/move-request-success" element={<FinalPage/>} />
              </Routes>
            </div>
            <Footer />
          </div>
          </MoveItemDetailProvider>
        </BuildingProvider>
      </MoveAddressProvider>
    </Router>
  );
};

export default App;
