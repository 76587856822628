import React, {useRef, useState } from "react";
import Header from "../components/Header";
import Autocomplete from "react-google-autocomplete";
import { useNavigate } from "react-router-dom";
import { useMoveAddress } from "../contexts/MoveAdressContext";

const GOOGLE_API_KEY = "AIzaSyBCQTwnaOYpwymYIY6HkEkLadGU2FSep58"; 

const MoveAddressDetail: React.FC = () => {
  const { moveOutAddress, moveInAddress, setMoveOutAddress, setMoveInAddress } = useMoveAddress();
  const [errors, setErrors] = useState<{ moveOutAddress?: string; moveInAddress?: string }>({});
  const navigate = useNavigate();


  const handleMoveOutAdressChange = (place: google.maps.places.PlaceResult) => {
    if (!place || !place.geometry || !place.address_components) {
      console.error("Geçerli bir konum seçilmedi veya eksik veri alındı.", place);
      return;
    }
  
    setMoveOutAddress({
      street: place.formatted_address || "",
      postcode: place.address_components?.find((comp) =>
        comp.types.includes("postal_code")
      )?.long_name || "",
      location: 
        place.address_components?.find((comp) =>
          comp.types.includes("neighborhood") || 
          comp.types.includes("sublocality_level_1") || 
          comp.types.includes("sublocality") || 
          comp.types.includes("locality") || 
          comp.types.includes("administrative_area_level_2")
        )?.long_name || 
        `${place.geometry.location?.lat?.() || "0"}, ${place.geometry.location?.lng?.() || "0"}`
    });
  };

  const handleMoveInAdressChange = (place: google.maps.places.PlaceResult) => {
    if (!place || !place.geometry || !place.address_components) {
      console.error("Geçerli bir konum seçilmedi veya eksik veri alındı.", place);
      return;
    }
  
    setMoveInAddress({
      street: place.formatted_address || "",
      postcode: place.address_components?.find((comp) =>
        comp.types.includes("postal_code")
      )?.long_name || "",
      location: 
        place.address_components?.find((comp) =>
          comp.types.includes("neighborhood") || 
          comp.types.includes("sublocality_level_1") || 
          comp.types.includes("sublocality") || 
          comp.types.includes("locality") || 
          comp.types.includes("administrative_area_level_2")
        )?.long_name || 
        `${place.geometry.location?.lat?.() || "0"}, ${place.geometry.location?.lng?.() || "0"}`
    });
  };

  const handleMoveOutChange = (key: string, value: string) => {
    setMoveOutAddress({ ...moveOutAddress, [key]: value });
  };

  const handleMoveInChange = (key: string, value: string) => {
    setMoveInAddress({ ...moveInAddress, [key]: value });
  };

  const handleNextClick = () => {
    const newErrors: { moveOutAddress?: string; moveInAddress?: string } = {};

    if (!moveOutAddress.street || !moveOutAddress.postcode || !moveOutAddress.location) {
      newErrors.moveOutAddress = "* All fields for move out address are required.";
    }

    if (!moveInAddress.street || !moveInAddress.postcode || !moveInAddress.location) {
      newErrors.moveInAddress = "* All fields for move in address are required.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }



    navigate("/building-detail");
  };

  return (
    <div className="bg-gray-100">
      <Header />
      <section className="bg-cover bg-center text-white py-10" style={{ backgroundImage: "url('/images/moving_illustration.jpg')", minHeight: '43vh' }}></section>
      <div className="grid grid-cols-12 gap-4 p-4">
        <div className="col-span-9 text-white p-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-white p-6 rounded-lg shadow-md h-auto">
              <div className="font-semibold text-indigo-600 mb-4">ADDRESS OF MOVE OUT LOCATION</div>
              {errors.moveOutAddress && <p className="text-red-500 text-sm">{errors.moveOutAddress}</p>}
              <Autocomplete
                apiKey={GOOGLE_API_KEY}
                onPlaceSelected={handleMoveOutAdressChange}                
                className="border rounded p-2 mb-2 w-full text-black"
              />
              <input
                type="text"
                value={moveOutAddress.postcode}
                onChange={(e) => handleMoveOutChange("postcode", e.target.value)}
                placeholder="Postcode *"
                className="border rounded p-2 mb-2 w-full text-black"
              />
              <input
                type="text"
                value={moveOutAddress.location}
                onChange={(e) => handleMoveOutChange("location", e.target.value)}
                placeholder="Location *"
                className="border rounded p-2 w-full text-black"
              />
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md h-auto">
              <div className="font-semibold text-indigo-600 mb-4">ADDRESS OF MOVE IN LOCATION</div>
              {errors.moveInAddress && <p className="text-red-500 text-sm">{errors.moveInAddress}</p>}
              <Autocomplete
                apiKey={GOOGLE_API_KEY}
                onPlaceSelected={handleMoveInAdressChange}
                className="border rounded p-2 mb-2 w-full text-black"
                placeholder="Street, House number, City *"
              />
              <input
                type="text"
                value={moveInAddress.postcode}
                onChange={(e) => handleMoveInChange("postcode", e.target.value)}
                placeholder="Postcode *"
                className="border rounded p-2 mb-2 w-full text-black"
              />
              <input
                type="text"
                value={moveInAddress.location}
                onChange={(e) => handleMoveInChange("location", e.target.value)}
                placeholder="Location *"
                className="border rounded p-2 w-full text-black"
              />
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <button
              className="bg-gray-400 text-white px-4 py-2 rounded-lg shadow-md hover:bg-gray-500 focus:outline-none mr-4"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
            <button
              className="bg-green-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none"
              onClick={handleNextClick}
            >
              Next
            </button>
          </div>
        </div>
        <div className="col-span-3 bg-white border border-gray-200 rounded-lg shadow-md mt-4 mb-5">
          <div className="bg-indigo-600 text-white text-lg font-semibold p-3 rounded-t-lg">
            Book now and benefit from stress-free move
          </div>

          <div className="p-4">
            <p className="text-gray-700 text-base">
              <span className="text-green-700 mr-2">✓</span> Your advantages with MoveAgain
            </p>
            <p className="text-gray-700 text-base">
              <span className="text-green-700 mr-2">✓</span> Online planner
            </p>
            <p className="text-gray-700 text-base">
              <span className="text-green-700 mr-2">✓</span> Personal customer service
            </p>
            <p className="text-gray-700 text-base">
              <span className="text-green-700 mr-2">✓</span> Date guarantee
            </p>
            <p className="text-gray-700 text-base">
              <span className="text-green-700 mr-2">✓</span> Last minute booking
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoveAddressDetail;