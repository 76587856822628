import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";

interface Address {
  street: string;
  postcode: string;
  location: string;
}

interface MoveAddressContextProps {
  moveOutAddress: Address;
  moveInAddress: Address;
  setMoveOutAddress: (address: Address) => void;
  setMoveInAddress: (address: Address) => void;
}

const MoveAddressContext = createContext<MoveAddressContextProps | undefined>(undefined);

export const MoveAddressProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [moveOutAddress, setMoveOutAddress] = useState<Address>(() => {
    const saved = localStorage.getItem('moveOutAddress');
    return saved ? JSON.parse(saved) : { street: "", postcode: "", location: "" };
  });

  const [moveInAddress, setMoveInAddress] = useState<Address>(() => {
    const saved = localStorage.getItem('moveInAddress');
    return saved ? JSON.parse(saved) : { street: "", postcode: "", location: "" };
  });

  useEffect(() => {
    localStorage.setItem('moveOutAddress', JSON.stringify(moveOutAddress));
  }, [moveOutAddress]);

  useEffect(() => {
    localStorage.setItem('moveInAddress', JSON.stringify(moveInAddress));
  }, [moveInAddress]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      localStorage.removeItem("moveOutAddress");
      localStorage.removeItem("moveInAddress");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <MoveAddressContext.Provider value={{ moveOutAddress, moveInAddress, setMoveOutAddress, setMoveInAddress }}>
      {children}
    </MoveAddressContext.Provider>
  );
};

export const useMoveAddress = () => {
  const context = useContext(MoveAddressContext);
  if (!context) {
    throw new Error("useMoveAddress must be used within a MoveAddressProvider");
  }
  return context;
};