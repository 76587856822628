import React, { createContext, useContext, useState, useEffect } from 'react';

export interface Item {
  id: string;
  name: string;
  icon: string;
  categoryId: number;
  quantity: number;
}

interface MoveItemDetailContextProps {
  items: Item[];
  setItems: React.Dispatch<React.SetStateAction<Item[]>>;
  selectedItems: Item[];
  setSelectedItems: React.Dispatch<React.SetStateAction<Item[]>>;
  quantities: { [key: string]: number };
  setQuantities: React.Dispatch<React.SetStateAction<{ [key: string]: number }>>;
}

const MoveItemDetailContext = createContext<MoveItemDetailContextProps | undefined>(undefined);

interface MoveItemDetailProviderProps {
  children: React.ReactNode;
}

export const MoveItemDetailProvider: React.FC<MoveItemDetailProviderProps> = ({ children }) => {
  const [items, setItems] = useState<Item[]>([]);
  const [selectedItems, setSelectedItems] = useState<Item[]>(() => {
    const saved = localStorage.getItem('selectedItems');
    return saved ? JSON.parse(saved) : [];
  });
  const [quantities, setQuantities] = useState<{ [key: string]: number }>(() => {
    const saved = localStorage.getItem('quantities');
    return saved ? JSON.parse(saved) : {};
  });

  useEffect(() => {
    localStorage.setItem('selectedItems', JSON.stringify(selectedItems));
  }, [selectedItems]);

  useEffect(() => {
    localStorage.setItem('quantities', JSON.stringify(quantities));
  }, [quantities]);

  
  useEffect(() => {
    const handleUnload = () => {
      localStorage.removeItem("moveOutItems");
      localStorage.removeItem("moveInItems");
    };

    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  return (
    <MoveItemDetailContext.Provider value={{ items, setItems, selectedItems, setSelectedItems, quantities, setQuantities }}>
      {children}
    </MoveItemDetailContext.Provider>
  );
};

export const useMoveItemDetail = () => {
  const context = useContext(MoveItemDetailContext);
  if (!context) {
    throw new Error('useMoveItemDetail must be used within a MoveItemDetailProvider');
  }
  return context;
};