import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <h4 className="font-semibold">Contact Us</h4>
            <p>Phone: 058 715 11 66</p>
            <p>Tusarova 1384, Prague 170 00</p>
            <p>Czechia</p>
            <p>info@happymove.co</p>
          </div>
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <h4 className="font-semibold">HappyMove</h4>
            <ul>
              <li><a href="#" className="text-gray-400 hover:text-white">About us</a></li>
              <li><a href="#" className="text-gray-400 hover:text-white">Contact us</a></li>
              <li><a href="#" className="text-gray-400 hover:text-white">FAQ</a></li>
            </ul>
          </div>
          <div className="w-full md:w-1/3">
            <h4 className="font-semibold">Services</h4>
            <ul>
              <li><a href="#" className="text-gray-400 hover:text-white">Calculate moving costs</a></li>
              <li><a href="#" className="text-gray-400 hover:text-white">Calculate cleaning costs</a></li>
            </ul>
          </div>
        </div>
        <p className="text-center text-gray-500 mt-8">© 2024 HappyMove. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;